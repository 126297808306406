// Generated by purs version 0.13.8
"use strict";
var Control_Category = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Category.purs");
var Control_Semigroupoid = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Semigroupoid.purs");
var Data_Function = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Function.purs");
var Data_Symbol = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Symbol.purs");
var Effect_Uncurried = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/effect/v2.0.1/src/Effect/Uncurried.purs");
var Record = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/record/v2.0.2/src/Record.purs");
var Type_Data_RowList = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Type/Data/RowList.purs");
var EventFn = function (x) {
    return x;
};
var Merge = function (mergeImpl) {
    this.mergeImpl = mergeImpl;
};
var unsafeEventFn = EventFn;
var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;
var mergeNil = new Merge(function (v) {
    return function (v1) {
        return function (v2) {
            return {};
        };
    };
});
var mergeImpl = function (dict) {
    return dict.mergeImpl;
};
var mergeCons = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictCons1) {
            return function (dictLacks) {
                return function (dictLacks1) {
                    return function (dictMerge) {
                        return new Merge(function (v) {
                            return function (fns) {
                                return function (a) {
                                    var v1 = mergeImpl(dictMerge)(Type_Data_RowList.RLProxy.value)(Record["delete"](dictIsSymbol)()()(Data_Symbol.SProxy.value)(fns));
                                    var v2 = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(fns);
                                    return Record.insert(dictIsSymbol)()()(Data_Symbol.SProxy.value)(v2(a))(v1(a));
                                };
                            };
                        });
                    };
                };
            };
        };
    };
};
var merge = function (dictRowToList) {
    return function (dictMerge) {
        return mergeImpl(dictMerge)(Type_Data_RowList.RLProxy.value);
    };
};
var handler_ = function ($21) {
    return Effect_Uncurried.mkEffectFn1(Data_Function["const"]($21));
};
var handler = function (v) {
    return function (cb) {
        return function ($22) {
            return cb(v($22))();
        };
    };
};
var categoryBuilder = Control_Category.categoryFn;
var syntheticEvent = Control_Category.identity(categoryBuilder);
module.exports = {
    unsafeEventFn: unsafeEventFn,
    handler: handler,
    handler_: handler_,
    syntheticEvent: syntheticEvent,
    merge: merge,
    Merge: Merge,
    mergeImpl: mergeImpl,
    semigroupoidBuilder: semigroupoidBuilder,
    categoryBuilder: categoryBuilder,
    mergeNil: mergeNil,
    mergeCons: mergeCons
};

