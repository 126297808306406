// Generated by purs version 0.13.8
"use strict";
var $foreign = require("/home/runner/work/jackpooley.io/jackpooley.io/app/Utils/ThemeToggle.js");
var Control_Applicative = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Applicative.purs");
var Effect_Console = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/console/v4.4.0/src/Effect/Console.purs");
var React_Basic_DOM_Generated = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM/Generated.purs");
var React_Basic_Events = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/Events.purs");
var React_Basic_Hooks = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks.purs");
var React_Basic_Hooks_Internal = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks/Internal.purs");
var Type_Equality = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/type-equality/v3.0.0/src/Type/Equality.purs");
var mkDarkToggle = React_Basic_Hooks.component()()()("Darkmode")(function (props) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
            className: "absolute top-0 right-0 pt-2 mt-8 mr-8",
            id: "switch-container",
            children: [ React_Basic_DOM_Generated.label()({
                className: "switch",
                onClick: React_Basic_Events.handler_(Effect_Console.log("hello")),
                children: [ React_Basic_DOM_Generated.input()({
                    type: "checkbox",
                    onClick: React_Basic_Events.handler_(function __do() {
                        $foreign.toggleDarkMode(v.value0);
                        return v.value1(function (on) {
                            return !on;
                        })();
                    })
                }), React_Basic_DOM_Generated.span()({
                    className: "toggle-round"
                }) ]
            }) ]
        }));
    });
});
module.exports = {
    mkDarkToggle: mkDarkToggle
};

