// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Applicative.purs");
var Data_Functor = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Functor.purs");
var Pages_Navigation = require("/home/runner/work/jackpooley.io/jackpooley.io/app/Components/Navigation.purs");
var React_Basic = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic.purs");
var React_Basic_DOM = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM.purs");
var React_Basic_DOM_Generated = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM/Generated.purs");
var React_Basic_DOM_Internal = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM/Internal.purs");
var React_Basic_DOM_SVG = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM/SVG.purs");
var React_Basic_Hooks = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks.purs");
var React_Basic_Hooks_Internal = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks/Internal.purs");
var Type_Equality = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/type-equality/v3.0.0/src/Type/Equality.purs");
var Utils_ThemeToggle = require("/home/runner/work/jackpooley.io/jackpooley.io/app/Utils/ThemeToggle.purs");
var renderHome = function (slots) {
    return function (props) {
        var hero = React_Basic_DOM_Generated.main()({
            className: "pl-8 md:pl-12 lg:pl-24 pb-24 flex self-center bg-white dark:bg-black",
            style: React_Basic_DOM_Internal.css({
                minHeight: "100vh"
            }),
            children: [ React_Basic_DOM_Generated.div()({
                className: "self-end",
                style: React_Basic_DOM_Internal.css({}),
                children: [ React_Basic_DOM_Generated.div()({
                    className: "font-light text-left m-4 leading-snug text-3xl md:leading-snug md:text-5xl",
                    children: [ React_Basic_DOM_Generated.img()({
                        className: "object-cover h-24 w-24 md:h-48 md:w-48 mb-12 rounded-full shadow-md shadow-white border border-black dark:border-white border-opacity-10",
                        width: "192",
                        height: "192",
                        src: "/Avatar@2x.png",
                        alt: "Avatar"
                    }), React_Basic_DOM_Generated.h1()({
                        className: "font-medium text-black dark:text-white mt-4",
                        children: [ React_Basic_DOM.text("Jack Pooley") ]
                    }), React_Basic_DOM_Generated.h2()({
                        className: "font-extralight text-black dark:text-white text-opacity-60 mb-4",
                        children: [ React_Basic_DOM.text("Building "), React_Basic_DOM_Generated.a()({
                            href: "https://polyglotsdk.xyz",
                            title: "Polyglot - polyglotsdk.xyz",
                            className: "font-medium text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-600 contrast-200 brightness-200",
                            children: [ React_Basic_DOM.text("Polyglot") ]
                        }) ]
                    }) ]
                }) ]
            }) ]
        });
        var footer = React_Basic_DOM_Generated.a()({
            href: "#___gatsby",
            children: [ React_Basic_DOM_Generated.footer()({
                className: "pl-8 md:pl-12 lg:pl-24 pt-16 pb-16 bg-black",
                children: [ React_Basic_DOM_Generated.div()({
                    className: "m-4 w-3 md:w-5",
                    children: [ React_Basic_DOM_SVG.svg()({
                        viewBox: "0 0 32 46",
                        children: [ React_Basic_DOM_SVG.path()({
                            fill: "white",
                            stroke: "none",
                            d: "M16,0 C15.3589744,0.000979344729 14.8119658,0.228899573 14.3589744,0.683760684 L0.683760684,14.3589744 C0.228810541,14.8118768 0.00089031339,15.3588853 0,16 C0.000979344729,16.6410256 0.228899573,17.1880342 0.683760684,17.6410256 C1.13675214,18.0958868 1.68376068,18.323807 2.32478632,18.3247863 C2.96581197,18.323807 3.51282051,18.0958868 3.96581197,17.6410256 L13.6752137,7.93162393 L13.6752137,43.3504274 C13.676193,43.991453 13.9041132,44.5384615 14.3589744,44.991453 C14.8119658,45.4463141 15.3589744,45.6742343 16,45.6752137 C16.6410256,45.6742343 17.1880342,45.4463141 17.6410256,44.991453 C18.0958868,44.5384615 18.323807,43.991453 18.3247863,43.3504274 L18.3247863,7.93162393 L28.034188,17.6410256 C28.4870905,18.0958868 29.034099,18.323807 29.6752137,18.3247863 C30.3162393,18.323807 30.8632479,18.0958868 31.3162393,17.6410256 C31.7711004,17.1880342 31.9990207,16.6410256 32,16 C31.9990207,15.3588853 31.7711004,14.8118768 31.3162393,14.3589744 L17.6410256,0.683760684 C17.1880342,0.228899573 16.6410256,0.000979344729 16,0 Z",
                            id: "Shape"
                        }) ]
                    }) ]
                }) ]
            }) ]
        });
        var content = (function () {
            var contentListUL = function (items) {
                var mapItemToJSX = function (item) {
                    return React_Basic_DOM_Generated.li()({
                        children: [ React_Basic_DOM_Generated.a()({
                            className: "hover:text-black dark:hover:text-white",
                            href: item.href,
                            children: [ React_Basic_DOM.text(item.title) ]
                        }) ]
                    });
                };
                var makeList = Data_Functor.map(Data_Functor.functorArray)(function (item) {
                    return mapItemToJSX(item);
                });
                return React_Basic_DOM_Generated.ul()({
                    className: "list-none text-3xl md:text-5xl font-light leading-snug md:leading-snug text-black dark:text-white text-opacity-75",
                    children: makeList(items)
                });
            };
            var contentList = function (title) {
                return function (items) {
                    return React_Basic_DOM_Generated.div()({
                        className: "m-4",
                        children: [ React_Basic_DOM_Generated.div()({
                            className: "text-lg md:text-xl font-light text-black dark:text-white text-opacity-50",
                            children: [ React_Basic_DOM.text(title) ]
                        }), contentListUL(items) ]
                    });
                };
            };
            return React_Basic_DOM_Generated.section()({
                className: "pl-8 md:pl-12 lg:pl-24 pt-16 pb-24 bg-black dark:bg-gray-100 bg-opacity-10",
                children: [ contentList("Elsewhere")([ {
                    title: "Twitter",
                    href: "https://www.twitter.com/jackpooley"
                }, {
                    title: "GitHub",
                    href: "https://www.github.com/jackpooley"
                }, {
                    title: "LinkedIn",
                    href: "https://www.linkedin.com/in/jpooley"
                } ]), React_Basic_DOM_Generated.hr()({
                    className: "ml-4 mt-16 mb-16 w-16 border-2 border-sold border-black dark:border-white border-opacity-10"
                }), contentList("Get in touch")([ {
                    title: "hi@jackpooley.io",
                    href: "mailto:hi@jackpooley.io"
                } ]) ]
            });
        })();
        return React_Basic.fragment([ hero, content, footer ]);
    };
};
var mkHome = (function () {
    var render = function (slots) {
        return function (props) {
            return renderHome(slots)(props);
        };
    };
    return function __do() {
        var navigation = Pages_Navigation.mkNavigation();
        var themeToggle = Utils_ThemeToggle.mkDarkToggle();
        return React_Basic_Hooks.component()()()("Home")(function (props) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(render({
                navigation: navigation,
                themeToggle: themeToggle
            })(props));
        })();
    };
})();
var mkHead = React_Basic_Hooks.component()()()("Head")(function (_props) {
    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.title()({
        children: [ React_Basic_DOM.text("Jack Pooley - jackpooley.io") ]
    }));
});
module.exports = {
    mkHead: mkHead,
    mkHome: mkHome
};

