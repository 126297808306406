const checkDarkMode = () => {
  if (typeof window === 'undefined') return false;
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

const checkIfDarkModeEnabled = () => {
  // if (typeof window === 'undefined') return false;
  const enabled = document.documentElement.classList.contains('mode-dark');
  console.log("checking if dark mode enabled, currently: " + (enabled ? "enabled" : "disabled"));
  return enabled;
}

// const watchDarkMode = () => {
//   if (typeof window === 'undefined') return;
//   if (!window.matchMedia) return;

//   window.matchMedia('(prefers-color-scheme: dark)').addListener(addDarkModeSelector);
// }

const addDarkModeSelector = () => {
  if (checkDarkMode()) {
    document.documentElement.classList.add('mode-dark');
  } else {
    document.documentElement.classList.remove('mode-dark');
  }
}

const setTheme = (enabled) => {
  console.log("setTheme, enabled: " + enabled);
  // const checked = document.getElementById('checked');
  const root = document.documentElement;
  if (enabled) {
    root.style.setProperty("--bg", "#101010")
    root.style.setProperty("--bg-light", "#303030")
    // checked.checked = false
    document.documentElement.classList.add('mode-dark');
  } else {
    root.style.setProperty("--bg", "#101010")
    root.style.setProperty("--bg-light", "#303030")
    // checked.checked = false
    document.documentElement.classList.remove('mode-dark');
  }
}

const toggleTheme = () => {
  let enabled = checkIfDarkModeEnabled();
  console.log("toggleTheme, currently: " + (enabled ? "enabled" : "disabled"));
  setTheme(!enabled);
}

exports.toggleDarkMode = function (enabled) {
  toggleTheme();
};

// exports.darkModeEnabled = checkIfDarkModeEnabled();
